// //
import request from "@/utils/request";
const prefix = '/order'

// 平台列表
export const platformList = (
    callback
) => {

    return request(`/shops/platformList`, {method: 'get'}, callback)
}

//异常订单list
/**
 *
 * @param storeCode 店铺code码
 * @param startTime 开始时间戳-精度秒
 * @param endTime 结束时间戳-精度秒
 * @param externalOrderId 第三方平台订单号
 * @param platformCode 平台码
 * @param page
 * @param perPage
 * @param callback
 * @returns {*}
 */
export const externalOrderList = (
    {
        storeCode,
        startTime,
        endTime,
        externalOrderId,
        platformCode,
        page,
        perPage
    },
        callback
) => {
    const params = {
        storeCode:storeCode || undefined,
        startTime:startTime || undefined,
        endTime:endTime || undefined,
        externalOrderId:externalOrderId || undefined,
        platformCode:platformCode || undefined,
        page,
        perPage
    }
    return request(`${prefix}/externalOrderList`,{params,method: 'get'}, callback)
}

//删除异常订单
export const delExternalOrder = (
    {
        externalOrderId
    },
    callback
) => {
    const params = {
        externalOrderId
    }
    return request(`${prefix}/delExternalOrder`,{params,method: 'delete'}, callback)
}

//异常订单备注
export const setExternalOrderMemo = (
    {
        externalOrderId,
        memo
    },
    callback
)=>{
  const data = {
      externalOrderId,
      memo
    }
    return request(`${prefix}/setExternalOrderMemo`,{data,method: 'put'}, callback)
}

//第三方异常订单关联本平台商品
export const linkPlatformProduct = (
    {
        designItemId,
        externalOrderItemId
    },
    callback
)=>{
    const data = {
        externalOrderItemId,
        designItemId
    }
    return request(`${prefix}/linkPlatformProduct`,{data,method: 'post'}, callback)
}