import request from "@/utils/request";
const prefix = '/design'
// 产品列表
// export const getMyDesign = (
//     {
//         themeId,
//         nameOrCode,
//         perPage,
//         startTime,
//         endTime,
//         page,
//         user_code
//     },
//     callback
// ) => {
//     const data = {
//         themeId,
//         nameOrCode,
//         perPage,
//         startTime,
//         endTime,
//         page,
//         user_code
//     }
//     return request(`${prefix}/myDesignProducts`, {params: data, method: 'get'}, callback)
// }






export const getMyDesign = (
    {
        themeId,
        perPage,
        page,
        synthesisTimeBetween,
        keyword,
        type
    },
    callback
) => {
    const data = {
        themeId,
        perPage,
        page,
        synthesisTimeBetween,
        keyword,
        type:type || undefined
    }
    return request('/design/getFinishedProductPaginate', {params: data, method: 'get'}, callback)
}








// 编辑信息
export const getDesignInfo = (
    {
        design_id
    },
    callback
) => {
    const data =  {
        designId:design_id
    }
    return request(`${prefix}/getEditDesignInfo`, {params: data, method: 'get'}, callback)
}
// 添加主题列表
export const addTheme = (
    {
        name,
        brief
    },
    callback
) => {
    const data = {
        name,
        brief
    }
    return request(`${prefix}/addTheme`, {data, method: 'post'}, callback)
}
// 获取主题
// export const getThemeList = (
//     callback
// ) => {
//     return request(`${prefix}/themeList`, {params: {}, method: 'get'}, callback)
// }


export const getThemeList = (
    callback
) => {
    return request('/design/getThemeList', {params: {}, method: 'get'}, callback)
}


// 编辑主题
export const editAlbum = (
    {
        themeId,
        name,
        brief
    },
    callback
) => {
    const data = {
        themeId,
        name,
        brief
    }
    return request(`${prefix}/editTheme`, {data, method: 'put'}, callback)
}
// 删除主题
export const deleteTheme = (
    {
        themeId
    },
    callback
) => {
    const data = {
        themeId
    }
    return request(`${prefix}/deleteTheme`, {params: data, method: 'delete'}, callback)
}
// 购物车列表
// export const getCartList = (
//     {
//        keyword,
//        design_id
//     },
//     callback
// ) => {
//     const data = {
//        keyword,
//        design_id
//     }
//     return request(`${prefix}/addCartList`, {params: data, method: 'get'}, callback)
// }
export const getCartList = (
    {
        keyword,
        design_id
    },
    callback
) => {
    const data = {
        keyword,
        designId:design_id
    }
    return request('/design/getAddCartList', {params: data, method: 'get'}, callback)
}
// 删除产品
// export const deleteDesign = (
//     {
//        designId
//     },
//     callback
// ) => {
//     const data = {
//        designId
//     }
//     return request(`${prefix}/deleteDesign`, {params: data, method: 'delete'}, callback)
// }
export const deleteDesign = (
    {
        designId
    },
    callback
) => {
    const data = {
        designId
    }
    return request('/design/deleteFinishedProduct', {params: data, method: 'delete'}, callback)
}






// 保存编辑
export const editDesignInfo = (
    {
       designMatrix,
       designId
    },
    callback
) => {
    const data = {
       designMatrix,
       designId
    }
    return request(`${prefix}/editDesignInfo`, { data, method: 'put'}, callback)
}
// 批量归类
export const updateDesignTheme = (
    {
        designId,
        themeId
    },
    callback
) => {
    const data =  {
        designId,
        themeId
    }
    return request(`${prefix}/updateDesignTheme`, { data, method: 'put'}, callback)
}

///design/downloadPicturesList下载成品图片列表

// export const downloadPicturesList = (
//     {
//         designId
//     },
//     callback
// )=>{
//     const params =  {
//         designId,
//     }
//     return request(`${prefix}/downloadPicturesList`, { params, method: 'get'}, callback)
// }

export const downloadPicturesList = (
    {
        designId
    },
    callback
)=>{
    const params =  {
        designId,
    }
    return request('design/getDownloadPicturesList', { params, method: 'get'}, callback)
}

//获取成品编辑详情
export const getFinishedProductDetails = (
    {
        designId
    },
    callback
)=>{
    const params =  {
        designId,
    }
    return request('design/getFinishedProductDetails', { params, method: 'get'}, callback)
}

//编辑成品
/**
 *
 * @param designId 成品id
 * @param cover 颜色封面组
 * cover.designColorId 成品颜色id
 * cover.img 图片原始路径，不含域名
 * @param productName 产品名称
 * @param describe 产品描述
 * @param tags 标签
 * @param price 定价组 (数组)
 * price.itemId 成品项目id
 * sellingPrice 建议售价
 * sellingMsrp 市场价
 * @param callback
 * @returns {*}
 */

export const editFinishedProduct = (
    {
        designId,
        cover,
        name,
        describe,
        price,
        tags,
        sellingPrice,
        sellingMsrp,
    },
    callback
)=>{
    const data =  {
        designId,
        cover,
        name,
        describe,
        price,
        tags,
        sellingPrice,
        sellingMsrp,
    }
    return request('design/editFinishedProduct', { data, method: 'put'}, callback)
}
