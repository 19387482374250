<template>
  <div>
    <!--    //搜索-->
    <el-form>
      <el-row :gutter="30">
        <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
          <el-form-item label="关键字">
            <el-input v-model="keyWord" placeholder="请输入第三方订单号" size="mini"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
          <el-form-item label="店铺">
            <el-select v-model="shop" placeholder="请选择活动区域" size="mini" clearable>
              <el-option v-for="item in shopList" :label="item.name" :value="item.code" :key="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="7" :xl="7">
          <el-form-item label="平台">
            <el-select v-model="platform" placeholder="请选择出单平台" size="mini" clearable>
              <el-option v-for="item in platformArr" :key="item.id"  :value="item.code">
                {{item.name}}
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="8" :xl="8">
          <el-form-item label="创建日期">
            <el-date-picker
                v-model="creationTime"
                type="datetimerange"
                :picker-options="pickerOptions"
                size="mini"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="right">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :md="24" :lg="8" :xl="7">
          <el-button type="primary"  @click="onSubmit" size="mini">搜索</el-button>
          <el-button class="reset" @click="onReset" size="mini">重置</el-button>
        </el-col>
      </el-row>
    </el-form>


<!--    //list-->
<!--    title-->
    <el-row class="bg_row" :gutter="10">
      <el-col :span="9" style="padding-left: 30px">产品信息</el-col>
      <el-col :span="5">出单平台</el-col>
      <el-col :span="6">收件人信息</el-col>
<!--      <el-col :span="4">订单状态</el-col>-->
      <el-col :span="4" class="tac" style="text-align: center">操作</el-col>
    </el-row>

    <!--    数据list-->
    <div
        v-loading="loading"
        element-loading-text="拼命加载中"
        element-loading-spinner="el-icon-loading"
        element-loading-background="#fff"
    >
      <div class="fba_list" v-for="item in externalOrderArr" :key="item.id">
        <!--      上&#45;&#45;订单号-->
        <div class="orderNumber">
          <span class="pl-20">{{item.sys_create_date}}</span>
          <span class="pl-20" >第三方订单号：</span><span>{{item.external_order_id}}</span>
          <span class="pl-20" >店铺：</span><span>【亚马逊 / {{item.platform_store.name}}】</span>
        </div>
        <!--      下&#45;&#45;内容-->
        <el-row :gutter="10">
          <el-col :span="9"  class="el_col_box">
<!--            v-for="val in item.fba_items" :key="item.fba_item_id"-->
            <div class="p-10 flex border_b" v-for="val in item.external_order_item" :key="val.id">
              <div class="img_box">
                <el-image :src="noImage"></el-image>
              </div>
              <div class="content">
                <div class="content_title">{{val.title}}</div>
                <div class="item_content" style="margin-top: 10px" v-if="!!val.is_link">
                  <div>
                    <span>SKU：</span>
                    <span>{{val.seller_SKU}}</span>
                  </div>
                </div>
                <div v-else>
                  <el-button style="margin-top: 10px;" @click="relatedProducts(val.id)" size="mini" type="primary" >
                    <span>关联</span>
                  </el-button>
                </div>
              </div>

            </div>
          </el-col>
          <el-col :span="5" class="public">
            {{setPlatformCode(item.platform_code)}}
          </el-col>
          <el-col :span="6" class="public">
            <div>
              <span>收货人：</span>
              <span>{{item.full_name}}</span>
            </div>
            <div>
              <div>收货地址：</div>
              <div>{{item.address_line1}}</div>
            </div>
            <div>
              <div>电话：</div>
              <div>{{item.phone}}</div>
            </div>
          </el-col>
<!--          <el-col :span="4" class="public">-->
<!--            订单状态-->
<!--          </el-col>-->
          <el-col :span="4" class="tac public operation" style="text-align: center">
            <div @click="deleteAbnormalOrder(item.id)">删除</div>
            <div @click="remark(item)">备注</div>
          </el-col>
        </el-row>
      </div>

      <div v-if="noProductInfoShow" class="noProductInfo">
        <img :src="noOrder" alt="">
        <div>暂无订单信息</div>
      </div>
    </div>

<!--    关联产品-->
    <associate-abnormal
        v-model="relatedProductsShow"
        :abnormalProductId="abnormalProductId"
        @update="externalOrderList"
        @close="relatedProductsClose"
    />

<!--    备注信息-->
    <remark-info
        v-model="remarkShow"
        :remarkId="remarkId"
        @close="remarkClose"
        :memo="memo"
        @update="externalOrderList"
    />

    <paging :meta="meta" @currentPage="currentPage"/>
  </div>
</template>

<script>
import noOrder from '@/assets/noOrder.png';
import Paging from "@/components/publicTab/index";
import {shopSelect} from "@/api/productTemplate";
import noImage from "@/assets/noimage.png";
import AssociateAbnormal from "./components/associateAbnormal";
import RemarkInfo from "./components/remarkInfo";
import {delExternalOrder, externalOrderList, platformList} from "@/api/abnormalOrder";
import {timestamp} from "@/utils/timejs";

export default {
  name: "index",
  data:()=>({
    noOrder,
    shopList : [],
    noImage,
    keyWord:'',
    shop:'',//店铺
    creationTime:[],//时间
    relatedProductsShow:false,//关联产品
    platform:undefined,
    pickerOptions:{  // 超过当前时间禁选
      disabledDate(time) {
        return time.getTime() > Date.now()
      }
    },
    loading:true,
    remarkShow:false,
    page:1,
    perPage:5,
    meta:{},
    remarkId:null,//备注id
    memo:null,//备注信息
    abnormalProductId:null,//异常产品id
    platformArr:[] ,//平台list
    externalOrderArr:[],//异常订单list
    noProductInfoShow:false,//无订单显示状态

  }),
  components:{
    AssociateAbnormal,
    RemarkInfo,
    Paging
  },
  mounted() {
    //店铺
    this.shopSelect()
    //平台list
    this.platformList()
    //异常订单List
    this.externalOrderList()
  },
  methods:{
    //获取店铺
    async shopSelect() {
      try {
        const data = await shopSelect()
        this.shopList = data?.data
      } catch (e) {
        const {msg} = e
        this.$message.error(msg)
      }
    },

    //平台list
    async platformList(){
      try {
        const { data } = await platformList()
        this.platformArr = data?.list
      }catch (e) {
        this.$message.error(e?.msg)
      }
    },

    //异常订单
    async externalOrderList(){
      this.loading = true
      try {
        const { data } = await externalOrderList({
          page:this.page,
          perPage:this.perPage,
          storeCode:this.shop,
          externalOrderId:this.keyWord,
          platformCode:this.platform,
          startTime:timestamp(this.creationTime[0]),
          endTime:timestamp(this.creationTime[1])
        })
        this.externalOrderArr = data?.list
        this.meta = data?.meta
        this.perPage = parseInt(data?.meta?.per_page)
        this.page = data?.meta?.current_page
        !this.externalOrderArr.length ?   this.noProductInfoShow = true : this.noProductInfoShow = false
      }catch (e) {
        this.$message.error(e?.msg)
      }
      this.loading = false
    },


    onSubmit(){
      this.page = 1
      this.perPage = 10
      this.externalOrderList()
    },

    onReset(){
          this.shop = ''//店铺
          this.creationTime = []//时间
          this.platform = undefined
          this.keyWord = ''
          this.externalOrderList()
    },
    //关联
    relatedProducts(id){
      this.abnormalProductId = id
      this.relatedProductsShow = true
    },
    relatedProductsClose(){
      this.abnormalProductId = null
    },

    //删除
    deleteAbnormalOrder(id){
      console.log(this)
        this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          try {
            const { msg } = await delExternalOrder({
              externalOrderId:id
            })
            this.$message({
              type: 'success',
              message: msg
            });
           await this.externalOrderList()
          }catch (e) {
            this.$message.error(e?.msg)
          }

        }).catch(() => {
          return false
        });

    },

    //region备注
    remark(item){
      console.log(item)
      this.memo = item.memo
      this.remarkId = item.id
      this.remarkShow = true
    },

    remarkClose(){
      this.remarkId = null
    },
    //endregion

    currentPage(e){
      this.page = e
      this.externalOrderList()
    },
  },
  computed:{
    // 异常平台
    setPlatformCode(){
      let name
      return (val)=>{
        this.platformArr.forEach(item=>{
          if(item.code === val) {
            name = item.name
          }
        })
        return name
      }
    }
  }
}
</script>

<style scoped lang="less">
.flex {
  display: flex;
}
.mt-10 {
  margin-top: 10px;
}
.p-10 {
  padding: 10px;
}
.pl-20 {
  padding-left: 20px;
  color: #909399;
}
/deep/.el-form-item__content {
  flex: 1;
  width: 100%;
}
/deep/.el-input .el-input--mini {
  width: 100%;
}
/deep/.el-select--mini {
  width: 100%;
}
/deep/.el-input__inner {
  width: 100% !important;
}
/deep/.el-form-item {
  display: flex;
  width: 100%;
}
/deep/.el-form-item__label {
  width: auto !important;
}
/deep/.el-col {
  border: 1px solid transparent;
}
.reset {
  margin-top: 7px;
}
.title {
  background: #F7F7F7;
  padding: 20px;
  font-weight: 600;
}
.fba_list {
  width: 100%;
  //height: 300px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  .orderNumber {
    height: 30px;
    border-bottom: 1px solid #ccc;
    padding-left: 10px;
    line-height: 30px;
    font-size: 13px;

  }
  .border_b {
    //border-top: 1px solid #ccc;
    height: 100%;

  }
  .el_col_box {
    height: 100%;
    border-right: 1px solid #ccc;
    padding-right: 0 !important;
    & > div:nth-child(1) {
      border-top: none;
    }

  }
}
.bg_row {
  font-weight: 600;
  margin-top: 10px;
  background: #F7F8FA;
  line-height: 40px;
  margin-left: 0 !important;
  margin-right: 0 !important;
  margin-bottom: 20px;
}
.content {
  display: flex;
  flex: 1;
  font-size: 12px;
  padding-left: 10px;
  flex-flow: column;
  justify-content: space-around;
  .content_title {
    letter-spacing: 0;
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;  /*要显示的行数*/
    -webkit-box-orient: vertical;
  }
  .item_content {
    display: flex;
    justify-content: space-between;
    .w-150 {
      width: 150px;
    }
    .product_unit_price {
      color: #cccccc;
      margin-left: 10px;
      text-decoration:line-through;
    }
  }
}
.img_box {
  width: 100px;
  height: 100px;
  position: relative;
  &>.el-image {
    width: 100%;
    height: 100%;
  }
}
/deep/.el-image {
  width: 100px;
  height: 100px;
}
.public {
  font-size: 12px;
  line-height: 18px;
  padding-top: 10px;
}
.operation {
  & > div:hover {
    color:#FA4A14 ;
    cursor:pointer;
  }
}
/deep/.el-form-item {
  margin-bottom: 0 !important;
}
.noProductInfo {
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #ccc;
}
</style>