<template>
  <el-dialog
      title="关联产品"
      :visible="value"
      width="50%"
      @open="open"
      :before-close="handleClose">
    <el-form :inline="true"  style="display: flex" >
      <el-row style="width: 100%;" :gutter="10">
        <el-col :span="9">
          <el-form-item label="关键字：" >
            <el-input size="mini" v-model="name" placeholder="请输入关键字" ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="产品分类："  >
            <el-select size="mini"   placeholder="请选择产品分类"  v-model="productClassification">
              <el-option v-for="item in ThemeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
        </el-col >
        <el-col :span="6">
          <el-form-item>
            <el-button class="search_btn"  size="mini" type="primary" @click="searchBtn">搜索</el-button>
            <el-button size="mini" @click="resetBtn">重置</el-button>
          </el-form-item>
        </el-col>
      </el-row>



    </el-form>

    <el-row class="title" :gutter="10">
      <el-col :span="12" style="padding-left: 20px;">产品信息</el-col>
      <el-col :span="7">规格</el-col>
      <el-col :span="2">状态</el-col>
      <el-col :span="3" style="text-align: center">操作</el-col>
    </el-row>

    <div class="product"
         v-loading="productShow"
         element-loading-text="拼命加载中"
         element-loading-spinner="el-icon-loading"
         element-loading-background="#fff"
    >
      <el-row class="product_box" :gutter="10" v-for="item in fbaFinishedProductSelectList" :key="item.id">
        <el-col :span="12" style="padding-left: 10px;" >
          <div class="flex product_info" >
            <div class="img_box">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="!!item.imgThumb.length ? item.imgThumb[0].img :moren"
                  fit="fit">
              </el-image>
            </div>
            <div class="content_box">
              <div class="product_name">
                {{item.name}}
              </div>
              <div style="margin-bottom: 5px">
                <span >SKU: </span>
                <span>{{item.sku}}</span>
              </div>
<!--              <div>-->
<!--                <span>类目：</span>-->
<!--                <span>男士时尚 / 帽衫、卫衣</span>-->
<!--              </div>-->
            </div>
          </div>
        </el-col>
        <el-col :span="7">
          <div class="mt-5 Specifications">
            <div >
              <div >
                <span>规格：</span>
                <span v-for="es in item.design_colors" :key="es.id">
                    <span v-for="e in es.design_item" :key="e.id" >
                      <span v-if="es.id === es.activeIndex" :class="{'sizeNameShow':e.id === e.activeSize}"  class="sizeName_box">
                        <span class="sizeName"  @click="sizeChange(e)">{{e.size_name}}</span>
                      </span>
                    </span>
                  </span>

              </div>
            </div>
            <div class="color">
              <span class="color_name">颜色：</span>

              <span
                  class="color_box"
                  @click="colorChange(val)"
                  :class="{'active':val.activeIndex === val.id}"
                  :style="{'background':'#'+val.color}"
                  v-for="val in item.design_colors"
                  :key="val.id"
              >
              </span>
            </div>
          </div>
        </el-col>
        <el-col :span="2" style="line-height: 100px; text-align: center">{{!!item.status ? '正常' : '失效'}}</el-col>
        <el-col :span="3" class="center">
          <el-button type="primary" size="mini" round @click="choiceProduct(item)">选择</el-button>
        </el-col>
      </el-row>
      <div v-if="noProductInfoShow" class="noProductInfo">
        <img :src="noOrder" alt="">
        <div>暂无订单信息</div>
      </div>
    </div>
    <!--    //分页-->
    <paging :meta="meta" @currentPage="currentPage"/>
  </el-dialog>
</template>

<script>
import moren from "@/assets/moren.png";
import { fbaFinishedProductSelect} from "@/api/order";
import Paging from "@/components/publicTab/index";
import {getThemeList} from "@/api/design";
import noOrder from '@/assets/noOrder.png';
import {linkPlatformProduct} from "@/api/abnormalOrder";

export default {
  name: "relatedProducts",
  components: {Paging},
  props:{
    value:{
      type:Boolean,
      default:false
    },
    abnormalProductId:{
      type:Number,
      default: null
    }
  },
  data: () => ({
    moren,
    noOrder,
    name:'',
    productClassification:'',
    fbaFinishedProductSelectList:[],//订单信息
    designItemList:[],//规格
    designItemId:null,//规格id
    noProductInfoShow:false,//产品信息分页
    productShow:true,//产品loading
    meta:{},//分页
    page:1,
    perPage:3,
    ThemeList:[],//产品分类list
  }),
  mounted() {

  },
  methods: {
    open(){
      this.fbaFinishedProductSelect()
      this.getThemeList()
    },
    //搜素
    searchBtn(){
      this.page = 1
      this.perPage = 3
      this.fbaFinishedProductSelect()
    },
    //重置
    resetBtn(){
      this.name = null
      this.productClassification = null
      this.searchBtn()
    },
    //产品分类
    async getThemeList(){
      try {
        const {data} = await getThemeList()
        this.ThemeList = data?.designTheme
      }catch (e) {
        this.$message.error(e?.msg)
      }
    },
    //分页
    currentPage(e){
      this.page = e
      this.fbaFinishedProductSelect()
    },
    // 规格变化
    sizeChange(e){
      console.log(e)
      this.designItemId = e.id
      this.fbaFinishedProductSelectList.forEach(item=>{
        item.design_colors.forEach((val,index)=>{
          //变换规格
          val.design_item.forEach(es=>{
            if(e.color_id === es.color_id) {
              es.activeSize = null
            }
            if(e.id === es.id) {
              es.activeSize = es.id
              item.sku = es.self_sku
            }
          })

        })
      })
    },
    // 颜色变化
    colorChange(e){

      this.fbaFinishedProductSelectList.forEach(item=>{
        item.design_colors.forEach((val,index)=>{
          //变换颜色
          if(e.design_id === val.design_id) {
            val.activeIndex = null
          }
          if(e.id === val.id) {
            val.activeIndex = val.id

          }

        })
      })
    },
    //关闭弹窗
    handleClose(){
      this.name = null
      this.productClassification = null
      this.$emit('input',false)
      this.$emit('close')
    },
    //选择关联设计产品
    async choiceProduct(item){

      item.design_colors.forEach(es=>{
        if(!!es.activeIndex) {
          es.design_item.forEach(val=>{
            if(!!val.activeSize) {
              console.log(val.id)
              this.designItemId = val.id
            }
          })
        }
      })

      try {
        const {msg} = await linkPlatformProduct({
          externalOrderItemId:this.abnormalProductId,
          designItemId:this.designItemId
        })
        this.$message.success(msg)
        this.handleClose()
        this.$emit('update')
      }catch (e) {
        this.$message.error(e?.msg)
      }

    },
    //获取设计成品列表
    async fbaFinishedProductSelect(){
      this.productShow = true
      try {
        const {data} = await fbaFinishedProductSelect({
          page:this.page,
          perPage:this.perPage,
          nameOrCode:this.name || undefined,
          themeId:this.productClassification || undefined
        })

        data?.data?.list.forEach(item=>{
          item.design_colors.forEach((val,index)=>{
            val.activeIndex = null
            if(!index) {
              val.activeIndex = val.id
              val.design_item.forEach((es,i)=>{
                if(!i) {
                  item.sku = es.self_sku
                }
              })
            }

            val.design_item.forEach((es,i)=>{
              es.activeSize = null
              if(!i) {
                es.activeSize = es?.id
              }
            })
          })
        })
        this.fbaFinishedProductSelectList = data?.data?.list
        this.meta = data?.meta
        this.perPage = parseInt(data?.meta?.per_page)
        !this.fbaFinishedProductSelectList.length ? this.noProductInfoShow = true :this.noProductInfoShow = false
        this.productShow = false
        console.log(this.fbaFinishedProductSelectList)
      }catch (e) {
        this.$message.error(e?.msg)
      }
    }

  },
  watch: {},

}
</script>

<style scoped lang="less">
/deep/.el-dialog__header {
  border-bottom: 1px solid #eee;
}
/deep/.el-dialog__body {
  padding: 10px 20px;
}
///deep/.el-input__inner {
//  height: 30px;
//}
.search_btn {
  margin-left: 10px;
  margin-top: 7px;
}
.noProductInfo {
  text-align: center;
  margin-top: 50px;
  font-size: 14px;
  font-weight: 700;
  color: #FA4A14;
}
.active {
  border: 2px solid #FA4A14 !important;
}
.product {
  height: 380px;
  //width: 740px;
  overflow-x:hidden;
  overflow-y:auto ;
}
.center {
  text-align: center;
  line-height: 100px;
}
.flex {
  display: flex;
}

.title {
  line-height: 40px;
  background: #eee;
  font-weight: 600;
}
/deep/.el-select {
  width: 100%;
}

.product_box {
  //  height: 350px;
  //  width: 740px;
  //  overflow-x:hidden;
  //  overflow-y:auto ;
  border-bottom: 1px solid #e8e8e8;
  margin-top: 10px;
  font-size: 12px;
  .product_info {
    .img_box {
      width: 100px;
      height: 100px;
    }
    .content_box {
      line-height: 20px;
      padding-left: 10px;
      .product_name {
        padding-top: 5px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 10px;
      }
    }
  }
  .Specifications {
    .color {
      .color_name {
        display: inline-block;
        padding-top: 10px;
        padding-bottom: 10px;
      }
      .color_box {
        vertical-align: middle;
        display: inline-block;
        width: 25px;
        height: 15px;
        padding-top: 20px;
        border: 1px solid #d8d8d8;
        margin: 5px;
        cursor: pointer;
      }
    }
    .sizeNameShow {
      //border: 2px solid #000000 !important;
      background: #FA4A14;
      color: #fff;
    }
    .sizeName_box {
      display: inline-block;
      padding: 0 5px;
      height: 25px;
      border: 1px solid #d8d8d8;
      margin: 5px;
      cursor: pointer;

      .sizeName {
        display: inline-block;
        text-align: center;
        line-height: 25px;
      }
    }
  }
}
.mt-5 {
  padding-top: 5px;
}
/* 滚动槽（轨道）宽高 */
::-webkit-scrollbar {
  width: 8px; /*对垂直流动条有效*/
  height: 20px; /*对水平流动条有效*/
  // background-color:red
}

/*定义滑块颜色、内阴影及圆角*/
::-webkit-scrollbar-thumb{
  border-radius: 7px;

  background-color: #E8E8E8;
}
</style>